import {calculateCellLevel} from "./LevelCalculatorService";
import {AREA_BREADTH} from "../constants/constants";

export default function generateRow(rowIndex) {
  let result = [];

  for (let i = 0; i < AREA_BREADTH; i++) {
    result.push(
      {
        imageName: null,
        unitsRendered: 0,
        position: {y: rowIndex, x: i},
        level: calculateCellLevel(AREA_BREADTH, rowIndex, i),
        map: []
      }
    );
  }

  return result;
}
