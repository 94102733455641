export function weightedRandom(options) {
  let i = 0;
  let random = 0;
  let weights = [];

  for (i = 0; i < options.length; i++) {
    weights[i] = options[i].weight + (weights[i - 1] || 0);
  }

  random = Math.random() * weights[weights.length - 1];

  for (i = 0; i < weights.length; i++) {
    if (weights[i] > random) {
      break;
    }
  }

  return options[i].item;
}

export function getRandomIndexFromArray(array) {
  return Math.floor(Math.random() * array.length);
}
