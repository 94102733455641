import React from "react";
import ScrollToTopButton from "../shared/components/ScrollToTopButton";

export default function FAQ() {
  return (
    <div className="p-3 position-relative">
      <h1 id="title" className="h2">FAQ</h1>
      <hr className="my-4" />

      <p>
        On this page you will find answers to some of the most frequently asked questions.
        <br />
        Got a question not answered here? <a href={'mailto:' + process.env.REACT_APP_CONTACT_EMAIL}>Please get in
        touch.</a>
      </p>

      <ul>
        <li className="h4"><a href="#what-is-the-idea">What is the idea?</a></li>
        <li className="h4">
          <a href="#how-can-i-show-off-my-pixels">How can I show off my pixels to other people?</a>
        </li>
        <li className="h4"><a href="#who-are-you">Who are you?</a></li>
        <li className="h4"><a href="#are-you-genuine">Are you genuine/is this a joke?</a></li>
        <li className="h4"><a href="#how-did-you-come-up-with-the-idea">How did you come up with the idea?</a></li>
        <li className="h4"><a href="#how-long-will-the-site-be-online">How long will the site be online?</a></li>
        <li className="h4"><a href="#what-will-you-do-with-the-money">What will you do with the money?</a></li>
        <li className="h4"><a href="#why-should-i-buy-your-pixels">Why should I buy your pixels?</a></li>
        <li className="h4"><a href="#is-it-safe">Is it safe?</a></li>
        <li className="h4"><a href="#why-cant-i-buy-one-single-pixel">Why can't I buy one single pixel?</a></li>
        <li className="h4"><a href="#how-can-you-view-a-billion">How can you view a billion pixels? Is it possible?</a>
        </li>
      </ul>

      <hr className="my-4" />

      <a href="#what-is-the-idea"><h3 id="what-is-the-idea">What is the idea?</h3></a>
      <p>
        The "billion dollar homepage" is an inspired project from the notorious <a
        href="http://www.milliondollarhomepage.com/" target="_blank">www.milliondollarhomepage.com</a>. The basic idea
        is mostly the same, people can buy space on the homepage, add a link to their website, an image and a message to
        that space for others
        to view and visit. The website will stay up for a very long time, 20 years are planned, but I see a full
        lifetime as the end goal.
      </p>
      <p>
        Creating a time capsule website with the potential for people to visit the bought
        spaces for as long as those websites are still available, eventually it will be fun for people to have a taste
        of
        the culture of the past and hunt for websites that still work ;)
        <br /><br />
        Why the "billion dollar homepage"? It was the only epic number after a million, duh. I thought that it will be
        an interesting project to make because it has a fun premise and the technologies are there also. I mean it could
        have been created long ago, but since nobody replicated the million idea into a billion one yet, I'll be the
        first to do so! Yay!
      </p>
      <p>
        I also think that bringing people together is incredible, if people come and visit the website
        from time to time, it's already a win in my book.
      </p>
      <hr className="my-4" />

      <a href="#how-can-i-show-off-my-pixels"><h3 id="how-can-i-show-off-my-pixels">How can I show off my pixels to
        other people?</h3></a>
      <p>
        You can show off your pixels to friends, family and other people on the internet by giving them the url to the
        square where you bought the pixels on like this: <span
        className="text-secondary-accent">{process.env.REACT_APP_WEBSITE_NAME + '/squares/<row>/<square>'}</span>. <br />
        For example, if you bought pixels on row 5 and square 10 the link will be &nbsp;
        <span className="text-secondary-accent">{process.env.REACT_APP_WEBSITE_NAME + '/squares/5/10'}</span>.
        <br />
        They can find your website image there. You can share this link anywhere and as many times as you want.
      </p>
      <hr className="my-4" />

      <a href="#who-are-you"><h3 id="who-are-you">Who are you?</h3></a>
      <p>
        I'm a web developer from Europe. I don't want to disclose my identity currently because I'm somewhat shy, maybe
        after 1 million pixels sold haha ;) or when the time is right. <br />
        Am I real? Well, somebody planned, implemented,
        deployed and still maintains this website so yeh, you can bet on it. If you're curious an email with a question
        and find out. Or just buy some pixels an see them magically appear on the website ^_^
      </p>
      <hr className="my-4" />
      <a href="#are-you-genuine"><h3 id="are-you-genuine">Are you genuine/is this a joke?</h3></a>
      <p>
        I love jokes, and even dark humour. But this is not one of them. This project is a demonstration of commitment
        to shaping an idea into a finished product. Because I have issues with finishing projects and not just working
        on them.
        <br />
        So it's more a challenge for myself, winning over my dark side (laziness and jumping to other projects) and it's
        also fun, if more people become involved in the project, that would be extra awesome!
      </p>
      <hr className="my-4" />
      <a href="#how-did-you-come-up-with-the-idea"><h3 id="how-did-you-come-up-with-the-idea">How did you come up with
        the idea?</h3></a>
      <p>
        The idea for the website popped up somewhere in 2019. When a friend of mine showed me the "million dollar
        homepage" while we were pondering about projects to work on as a side hustle and get infinitely rich!! I guess
        every developer goes through this a phase sometime in his/her career.
      </p>
      <p>
        But since we all know that the "get rich
        quick schemes" have little to no success, I've left the idea in the idea box. Ain't nobody got time for funny
        ideas, especially considering that even a small project done solo takes about 3 to 4 months of work, pair that
        with a full time job, and you've got yourself a risk that you don't want to take.
      </p>
      <p>
        It's March 2023 and since I've got plenty of time on my hands right now (don't ask about work), I thought that
        it would be great to get rid of my "New Shiny Object" syndrome and finally finish a small project for once,
        while also learning a new technology (React).
      </p>
      <p>
        And since the idea really stuck with me for such a long
        time, I thought I'd give it a chance. <br />
        The idea is interesting,
        I even added a few gamification elements to it and if it
        brings some money great, it can pay for it's hosting. If it brings in more, well then, I have a few more game
        projects I want to make and I'll have the possibility to work on those.<br />
        Cheers!
      </p>
      <hr className="my-4" />
      <a href="#how-long-will-the-site-be-online"><h3 id="how-long-will-the-site-be-online">How long will the site be
        online?</h3></a>
      <p>The plan is to have it online for 20 years as a starting point. But I think it would be awesome to have it
        online for 50 years or more, for me that would be an awesome achievement.
      </p>
      <hr className="my-4" />
      <a href="#what-will-you-do-with-the-money"><h3 id="what-will-you-do-with-the-money">What will you do with the
        money?</h3></a>
      <p>
        Uff, that's a tough one. I have many plans and they are quite diverse. Depending on the amount, it could go
        differently. Frankly I don't think the project will make any money, but I do hope that at a minimum it could pay
        for itself. If it brings in a little more, then new opportunity doors get opened.
      </p>
      <p>
        I have a list of projects I'd
        like to work on and working on them while also juggling a full time job that's often intense, doesn't leave much
        time for the former, sometimes at all. So, having a little more freedom to work on them would be a dream come
        true!
        <br /><br />
        I also love animals and help the local vet clinic by covering some of the costs for voluntaries that don't have
        the possibility to pay for strays that are in need. So some of the money will definitely go their way.
        I believe that we should strive to make the world around us a little better and if I can contribute to that even
        slightly, I can't ask for more.
        So be sure to visit my other projects.. Haha!
      </p>
      <hr className="my-4" />
      <a href="#why-should-i-buy-your-pixels"><h3 id="why-should-i-buy-your-pixels">Why should I buy your pixels?</h3>
      </a>
      <p>
        There are a few reasons I can think of. The most important is advertisment. The spot you buy here is like
        internet real estate, it will be here as long as the website exists. Your image and website has the potential to
        be seen by millions in the years to come. The images that stand out will gather even more attention and the
        price for this promotion is minuscule compared to what others pay for marketing these days. Pay once and have
        the image on the website for at least 20 years? Sign me up. <br /><br />
        Since you can promote someone else, you can make a gift to someone you know, be it a person you want to impress
        or a company you care about. Having your own little space on the internet sounds pretty cool. And I'd argue that
        coming together and building something side by side with millions of other people is exhilarating.
        <br /><br />
        The time capsule effect is another reason to buy the pixels. 10 years later, maybe more, you can come back and
        reminiscence about the nostalgia of the good old days and the culture back then. Show off your cool spot on the
        internet and laugh about it with your friends.
        <br /><br />
        Many years later it will be fun to find the websites and companies that still exist, because only the sturdiest
        will survive ;)
        <br /><br />
        And as a last reason, you'll make someone happy in the world with this purchase. Isn't that worth it ;)
      </p>
      <hr className="my-4" />
      <a href="#is-it-safe"><h3 id="is-it-safe">Is it safe?</h3></a>
      <p>
        The purchasing process is safe because the website uses Paypal as a payment system. Paypal is one of the safest
        and reliable service out there. So yes, the payment process is as safe as it can be.
        <br /><br />
        Would I put your pixels online if you finalize the transaction? I think that by the time anyone would like to
        make a purchase the website
        will already have multiple links/images already bought by different people (hopefully lol) and that will act as
        evidence that I will keep my end of the bargain.
        You can always write an email with any enquiries and wait for my reply as reassurance that there is indeed
        someone maintaining the project. You can find the email on the <a href="contact-me">Contact Me</a> page.
        <br /><br />
        At the end of the day, I'm an honest guy and you will get a full refund if something unexpected came up.
      </p>
      <hr className="my-4" />
      <a href="#why-cant-i-buy-one-single-pixel"><h3 id="why-cant-i-buy-one-single-pixel">Why can't I buy one single
        pixel?</h3></a>
      <p>
        A single pixel is too small to display something of value and the website would be pretty heavy on the eyes.
        This is why the pixels are sold in pixel units that consist of 10 x 10 pixels, so essentially <b>1 unit = 100
        pixels</b>.<br />
        No worries, since the squares have different levels attached to them, and the pricing depends on the level,
        you'll be able to find something in your range. <br />
        Just pick a square where you want to buy the units, check if they are still available for purchase and go for
        it.</p>
      <p>
        <b>Remember:</b> You can always <b>reserve</b> a spot if you haven't decided on what to put there yet or you
        can <b>buy a spot together</b>
        &nbsp; with your friends or family as an option.
      </p>
      <hr className="my-4" />
      <a href="#how-can-you-view-a-billion"><h3 id="how-can-you-view-a-billion">How can you view a billion pixels? Is it
        possible?</h3></a>
      <p>
        Technically it's possible to view a billion pixels at a time, but zooming out that much would just ruin the
        experience since everything would be so small and cramped together. This is why the viewing area is a big square
        that is divided into smaller squares of 1 million pixels.
      </p>
      <p>
        The viewing area has 32 rows with each row having 32
        squares. And in total there are 32 rows * 32 squares * 1 million pixels on each square = 1.024 billion pixels.
        Each user can view a square
        of 1 million pixels at a time, and navigate to other squares through the interface buttons at the top of the
        homepage. The squares in the rows as well as the pixel units on squares can be counted from top to bottom, left
        to right.
      </p>
      <p>
        Why 1.024 billion pixels and not 1 billion you ask? Well, initially I was envisioning a viewable area like a map
        that can be scrolled from top to bottom, left to right continuously and it would be a bit lame in my opinion if
        this area would have gaps in it, since you can't have a perfect square with only 1 billion pixels.
      </p>
      <p>
        This is why I made the decision of adding a few more squares for the sake of creating a huge viewable area as a
        square. So
        that eventually I can add continuous scrolling to the project and it would feel natural scrolling from end to
        end.
      </p>


      <hr className="my-5" />
      <div style={{position: "absolute", right: '60px'}}>
        <ScrollToTopButton />
      </div>
    </div>
  );
}
