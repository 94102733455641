import React from 'react';
import ReactDom from 'react-dom/client';
import Router from "./features/Router"

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './resources/scss/index.scss';
import 'animate.css/animate.min.css';

let root = ReactDom.createRoot(document.getElementById('root'));
root.render(
  <Router />
);
