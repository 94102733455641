import React from 'react';
import {NavLink, Outlet} from 'react-router-dom';
import Header from "./Header";
import "./Template.scss";
import Footer from "./Footer";

export default function Template() {
  return (
    <React.Fragment>
      <Header />

      <div className="row g-0 justify-content-center px-3">
        <div className="col-auto">
          <nav className="nav flex-column flex-sm-row">
            {[
              {href: '/', name: 'Homepage'},
              {href: 'buy-pixels', name: 'Buy Pixels'},
              {href: 'pixel-list', name: 'Pixel List'},
              {href: 'faq', name: 'FAQ'},
              {href: 'contact-me', name: 'Contact Me'},
            ].map((item) => (
              <NavLink
                to={item.href}
                className={({isActive}) => {
                  return 'navigation-link px-2 h4 ' + (isActive ? 'navigation-link--active' : '')
                }}
                key={item.href}>{item.name}</NavLink>
            ))}
          </nav>
        </div>
      </div>

      <div className="row g-0">
        <div className="col">
          <Outlet />
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}
