import React, {useState} from "react";
import BuySquares from "../features/BuySquares/components/BuySquares";
import {BsChevronDoubleDown, BsChevronDoubleUp} from "react-icons/bs";

export default function BuyPixels() {
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;
  let [showSquareDetails, setShowSquareDetails] = useState(false);

  return (
    <div className="p-3">
      <h1 className="h2">Buy Pixels</h1>
      <hr />

      <p>If you're looking for pixels, you've come to <a href="#buy-pixels">the right place!</a> We've got a billion of
        the little things.</p>
      <p>
        And since it's not realistic to view 1 billion pixels all at once, pixels on the Billion Dollar Homepage are
        grouped in squares of 1 million pixels that can easily be shown.
      </p>
      <p>
        In fact, you already saw the first square when you navigated to this website. <br />Pixels are available for
        purchase
        in units, 1 unit = 10 x 10 pixels = 100 pixel. You will see the homepage divided into 10,000 of them, 100 units
        across and 100 units down.
        The squares and units are counted from top to bottom, left to right.
        The reason for selling pixels in units is because anything smaller than 10x10 pixels would be too small to
        display anything meaningful.
      </p>
      <p>
        However, if you want to own less than 100 pixels - ex. one pixel - you could always club together with
        family/friends/colleagues and buy a single unit.
      </p>

      {showSquareDetails &&
        <div>
          <hr />
          <p>
            So how do we have 1 billion pixels on the website? To be exact, 1 billion 24 million pixels. This number
            comes
            from an area of 32 x 32 squares that each have 1 million pixels.
            So, to reiterate, there are 32 rows and on each row there are 32 squares that are viewable on the homepage,
            1024
            squares in total.
            At the moment the squares can only be viewed individually, but in the future I might add a mode where people
            can
            view the whole area continuously, like a big map.
          </p>
          <p>
            The squares have a little twist also. Each one has a level attached to it. You can think of the whole area
            as an onion with layers, where the
            outer layer is the lightest and the inner layer is the heaviest. There are 16 levels in total.
            The level affects the price of the pixels on the square and how often each level is viewed. Since there are
            so many viewable squares, rules had to be put in place for how often each square would show to the user.
          </p>
          <p>
            The user will see a random square when visiting the website, if s/he doesn't specifically ask for one in the
            URL. Only the squares that have pixels bought will be considered. The squares on the same level will have
            the same weight of being viewed, but
            the level 16 squares will be viewed 2 times as much as level 1 squares.
            So the higher the level of the square, the more often it will be viewed.
          </p>
        </div>
      }

      <div className="text-center m-1">
        <a href="#" onClick={() => {
          setShowSquareDetails(!showSquareDetails)
        }}>
          {showSquareDetails ?
            <>Less <br /><BsChevronDoubleUp /></>
            :
            <>More on squares and levels <br /><BsChevronDoubleDown /></>
          }
        </a>
      </div>

      <p>
        Pixels on level 1 costs ¢10 cents per pixel. Equating to $10 USD per unit. Each higher level costs ¢10 more per
        pixel. Meaning that each level is $10 USD more than the previous.
        This way anyone can buy pixels on the website by choosing the right level for themselves.
        You can purchase as many units of pixels as you like, on any square, as long as there are some available there.
      </p>
      <p>
        The live stats in the header will tell you how many pixels can be purchased overall on the website and the <a
        href="#buy-pixels">Buy Pixels</a> widget
        will help you understand how many units are left on the square you want to buy the units <b>(info on Step 2)</b>.
        <br />

        Please read all of the following <a href="#terms-and-conditions">Terms &amp; Conditions</a> if you are about to
        buy some
        pixels - <b>especially those marked
        in orange</b>.
        <br />
        Just so we all are on the same page :)
      </p>
      <h3 id="paypal-how-to-buy" className="mt-5">PayPal, how to buy</h3>
      <h4 className="mt-3">Step 1</h4>
      <p>Lower on the <a href="#buy-pixels">Buy Pixels</a> widget, select the square you want to buy the units on.</p>
      <h4 className="mt-3">Step 2</h4>
      <p>After selecting the square, you will see the information regarding the particular square.
        Click on the "Buy now" button to purchase the units with Paypal. You can select how many units you want to buy
        in the Paypal window.
      </p>
      <h4 className="mt-3">Step 3</h4>
      <p>
        Because the pixels are sold in units, please ensure you order the correct quantity of units when ordering. Once
        you have paid for your
        pixels, please e-mail at <a
        href={'mailto:' + contactEmail}>{contactEmail}</a> with the following:
      </p>

      <span className="text-secondary-accent">
        <ol>
          <li>Attach your image as a JPEG (must be correct size for the pixels purchased).
            If you bought 1 unit, your image should be 10x10 pixels.
          </li>
          <li>State where you want your graphic to be placed on the square (ex. 5 units across, 2 units down).
            Make sure you are certain about the position.
          </li>
          <li>State which website you would like your graphic linking to (must begin with 'http://' or 'https://')</li>
          <li>State what TITLE/ALT text you want for your image, max. 60 characters. TITLE/ALT text appears when
            people hover their cursor over your image.
          </li>
        </ol>

        <p><b>It's still worth getting in the queue as early as you can, in order to reserve your spot on
          the square. Please note if two or more people in the queue ask for the same position on the square, the
          person who ordered earliest will have priority.</b>
        </p>
      </span>

      <BuySquares />

      <h3 id="terms-and-conditions" className="gold mt-5">Terms and Conditions</h3>
      <ul>
        <li>
          The minimum purchase is 1 unit (1 unit = 10 x 10 pixels = 100 pixels) and since on the first level 1 pixel is
          10¢,
          the price for one unit is $10 USD and the maximum purchase is whatever is left on that particular square.
        </li>
        <li>
          You can also reserve the pixels in case you're waiting for the website or you're still deciding on the
          details. Purchase the units and decide later on what you want to have there.
          Reservations will show as black squares with a 'R' letter on it.
        </li>
        <li>
          The website will be online for at least 20 years starting from the day it launched, so at least
          until 1st March 2043, if all goes well, even more.
        </li>
        <li>
          <span><b>You can submit links to websites that you don't own, with the idea of (promotion, gift, surprise) someone</b>,
            but if the owner of the website reaches out and
            wants it removed (providing evidence), they can remove any part of the unit (the image, the link or the title).
          </span>&nbsp;
          <span className="text-secondary-accent">
            In worst case scenarios the space will become publicly available again.
          </span>
        </li>
        <li>
          When you "buy" my pixels you will not "own" any part of the homepage or this site, but rather you are
          granted the right to submit an image, title and link which will then be published on the homepage in the
          respective
          square and space you have paid for.
        </li>
        <li>You cannot change the image, title or link once you have submitted them, so you must be sure of the
          image &amp; link you want on the homepage. <span className="text-secondary-accent">The only exception is
            if you have a strong reason why you need a change to your image/link, ex. your site no longer exists, major
            structural change to your site, lawsuits, etc.
          </span></li>
        <li>
          <span className="text-secondary-accent">If your image and link are accepted and published on the homepage, you cannot get a
            refund.</span>
        </li>
        <li><span className="text-secondary-accent">No obscene/offensive/adult images/links will be accepted.
          The only exception is OnlyFans and Twitch, with the condition that the link itself won't lead to adult
          content, let's keep it civilized.
          I decide what is appropriate for my site. If you submit an image or link that is rejected, you will have the
          opportunity to
          provide an alternative, otherwise your money will be refunded and you will lose your pixels.
        </span></li>
        <li><span className="text-secondary-accent">If your link is accepted and you later change the content of the linked site to show
          offensive/obscene/adult material then your link will be removed.</span>
        </li>
        <li>If for whatever reason in the future you want your image and link removed from the website, you can do
          that, but you will not get a refund and the space will become publicly available again.
        </li>
        <li>Images must be the exact size you have paid for (ex. 1 unit is an image of 10x10 pixels )
        </li>
        <li>Images must be in JPEG format, and a reasonable file size.</li>
        <li>Links must be to web pages only and begin with http:// or https://.</li>
        <li>Funds must be fully recieved and cleared before your pixels go live on the website square.</li>
        <li>There might be occasional downtime for site maintenance but I'll try and keep these to a minimum.</li>
        <li>
          Once you have ordered and the funds have cleared, I will aim to get
          your pixels online within 24-48hrs (but please have patience, you can also email me for enquiries)
        </li>
      </ul>

      <p>
        Ok great, so now you're all set to buy some pixels. There is only one way to buy pixels at the
        moment, <b><a href="#paypal-how-to-buy">PayPal</a></b>.
      </p>
    </div>
  );
}
