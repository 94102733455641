import React, {useState} from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';

export default function ScrollToTopButton() {
  const [visible, setVisible] = useState(false);
  const defaultOpacity = 75;
  const [opacity, setOpacity] = useState(defaultOpacity);
  const style = {
    position: 'fixed',
    bottom: '70px',
    height: '20px',
    fontSize: '2.5rem',
    zIndex: '1',
    cursor: 'pointer',
    color: '#0dcaf0'
  }

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;

    if (scrolled > 300) {
      !visible && setVisible(true)
    } else if (visible) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <div style={style}>
      <FaArrowCircleUp className={[visible ? 'd-inline' : 'd-none', 'opacity-' + opacity].join(' ')}
        onClick={scrollToTop}
        onMouseOver={() => {
          setOpacity(100)
        }}
        onMouseLeave={() => {
          setOpacity(defaultOpacity)
        }}
      />
    </div>
  );
}
