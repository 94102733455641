import React from "react";

export default function Error404() {
  return (
    <div className="p-3 text-center">
      <h1>404</h1>
      <h3>Page couldn't be found. There's nothing here!</h3>
    </div>
  );
}
