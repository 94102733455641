import {getRandomIndexFromArray, weightedRandom} from "../../../shared/services/randomizers";
import levels from "./SquareLevelGeneratorService";

export default class SquareFromLevelProcessorService {
  #squares = [];

  constructor(squares) {
    this.#squares = squares;
  }

  #getSquaresWithRenderedUnits(squares) {
    return squares.flat().filter((item) => {
      return item.unitsRendered;
    });
  }

  #filterSquaresByLevel(squares, level) {
    return squares.filter((item) => {
      return item.level === level;
    });
  }

  #getLevelsForProcessing(squares) {
    return squares.reduce((cumulative, item) => {
      if (cumulative.indexOf(item.level) === -1) {
        cumulative.push(item.level);
      }

      return cumulative;
    }, []);
  }

  #pickRandomSquare(squares) {
    return squares[getRandomIndexFromArray(squares)];
  }

  #getActiveLevels(activeLevels) {
    return levels.filter((item) => {
      return activeLevels.indexOf(item.item) !== -1;
    });
  }

  execute() {
    let squareToShow;
    let renderableSquares = this.#getSquaresWithRenderedUnits(this.#squares);

    if (renderableSquares.length) {
      let filteredMappings = this.#getActiveLevels(this.#getLevelsForProcessing(renderableSquares));
      let levelToShow = weightedRandom(filteredMappings);
      let levelFilteredSquares = this.#filterSquaresByLevel(renderableSquares, levelToShow);
      squareToShow = this.#pickRandomSquare(levelFilteredSquares);

      // console.log('squares with rendered pixels', renderableSquares);
      // console.log('processed Levels', this.#getLevelsForProcessing(renderableSquares));
      // console.log('filtered mappings', filteredMappings);
      // console.log('weightedRandom', levelToShow);
      // console.log('levelFilteredSquares', levelFilteredSquares);
    } else {
      squareToShow = this.#squares[0][0];
    }

    // console.log('hi', squareToShow);
    // alert();

    return squareToShow;
  }
}
