import React, {useEffect, useState} from "react";
import SquareFromLevelProcessorService from "../../services/SquareFromLevelProcessorService";
import MainModal from "../../../../shared/components/MainModal";
import SquarePositionRenderer from "./components/SquarePositionRenderer";
import Button from "../../../../shared/components/Buttons/Button";


export default function SquaresFinder({position, squares, onSquareChange}) {
  const DIRECTION_UP = 2;
  const DIRECTION_DOWN = 3;
  const DIRECTION_LEFT = 1;
  const DIRECTION_RIGHT = 4;
  let [forceModalVisibility, setForceModalVisibility] = useState(0);
  let [modalMessage, setModalMessage] = useState('');
  let squareToShow = position ? squares[position.y][position.x] : (new SquareFromLevelProcessorService(squares)).execute();
  let squarePosition = {y: squareToShow.position.y, x: squareToShow.position.x};

  useEffect(() => {
    onSquareChange(squarePosition);
  }, []);

  function setNewSquarePositionBasedOnDirection(index) {
    let x = squarePosition.x;
    let y = squarePosition.y;
    let message = '';

    if (index === DIRECTION_LEFT) {
      if (x === 0) {
        message = 'Already on first square';
      } else {
        x--;
      }
    }

    if (index === DIRECTION_UP) {
      if (y === 0) {
        message = 'Already on top row';
      } else {
        y--;
      }
    }

    if (index === DIRECTION_DOWN) {
      if (y === squares.length - 1) {
        message = 'Already on last row';
      } else {
        y++;
      }
    }

    if (index === DIRECTION_RIGHT) {
      if (x === squares.length - 1) {
        message = 'Already on last square';
      } else {
        x++;
      }
    }

    if (message) {
      setModalMessage(message);
      setForceModalVisibility(state => ++state);

      return;
    }

    setModalMessage('');
    onSquareChange({y, x});
  }

  function setRandomSquare() {
    let y = Math.floor(Math.random() * squares.length);
    let x = Math.floor(Math.random() * squares.length);

    setModalMessage('');
    onSquareChange({y, x});
  }

  return (
    <React.Fragment>
      <div className="row g-0 align-items-end text-center text-sm-start px-3 py-1">
        <div className="col-12 col-sm mt-3 my-sm-0">
          <SquarePositionRenderer position={squarePosition} key={JSON.stringify(squarePosition)} />
        </div>
        <div className="col-12 col-sm-auto mt-3 my-sm-0 px-3">
          <Button onClick={() => {
            setRandomSquare();
          }}>
            Random
          </Button>
        </div>
        <div className="col-12 col-sm-auto mt-3 my-sm-0  text-center">
          <Button style={{marginBottom: '2px'}} onClick={() => {
            setNewSquarePositionBasedOnDirection(DIRECTION_UP);
          }}>
            Up
          </Button>
          <br />
          <Button onClick={() => {
            setNewSquarePositionBasedOnDirection(DIRECTION_LEFT);
          }}>
            Left
          </Button>
          <Button style={{marginLeft: '2px'}} onClick={() => {
            setNewSquarePositionBasedOnDirection(DIRECTION_DOWN);
          }}>
            Down
          </Button>
          <Button style={{marginLeft: '2px'}} onClick={() => {
            setNewSquarePositionBasedOnDirection(DIRECTION_RIGHT);
          }}>Right
          </Button>
        </div>
      </div>

      <MainModal message={modalMessage} key={forceModalVisibility} />
    </React.Fragment>
  );
}
