import React from "react";

export default function Footer() {
  return (
    <div className="row g-0 p-3 mt-4">
      <div className="footer col text-center">The Billion Dollar Homepage <b>© 2023</b>. All rights reserved. <br />
        I am not responsible for the content of external sites. Images featured on homepage are <b>©</b> of their
        respective owners.
      </div>
    </div>
  );
}
