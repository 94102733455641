import React from "react";

export default function ContactMe() {
  let contactEmail = process.env.REACT_APP_CONTACT_EMAIL;
  let ownersEmail = process.env.REACT_APP_OWNERS_EMAIL;

  return (
    <div className="p-3">
      <h1 className="h2">Contact Me</h1>
      <hr />
      <h4>
        Discord server
      </h4>
      <p>
        Join the BillionDollarHomepage discord server for any questions. <a href="https://discord.gg/8dDCTAK6zg"
        target="_blank">Join Link</a>
      </p>

      <h4 className="mt-4">
        Email
      </h4>
      <p>
        And if you prefer emails. <br />
        General messages & business questions: <a href={'mailto:' + contactEmail}>{contactEmail}</a><br />
        <b className="text-secondary-accent-light">Pixel owners</b> questions & discussions: <a
        href={'mailto:' + ownersEmail}>{ownersEmail}</a><br />
      </p>
      <p className="text-main-accent-gray">
        Feel free to get in touch!
      </p>
    </div>
  );
}
