import React, {useEffect, useRef, useState} from 'react';
import './PixelBoardTooltip.scss';
import ROUTE_NAMES from '../../../../pages/routes/RouteNames'
import {HashLink} from "react-router-hash-link";

export default function PixelBoardTooltip({
  linkPosition,
  linkName,
  linkHref,
  manageFixedPositionOnTooltip,
  isTooltipPositionFixed
}) {
  const ref = useRef(null);
  const DEFAULT_PADDING = 10;
  let [positionWidth, setPositionWidth] = useState(DEFAULT_PADDING);
  let [refWidth, setRefWidth] = useState('unset');
  const componentStyle = {
    pointerEvents: isTooltipPositionFixed ? 'initial' : 'none',
    left: linkPosition.x + positionWidth,
    top: linkPosition.y - 12,
    width: refWidth,
  };
  let [hyperlinkStyle, setHyperlinkStyle] = useState();
  let [closeButtonStyle, setCloseButtonStyle] = useState();
  let [showOnRightSide, setShowOnRightSide] = useState();

  useEffect(() => {
    let showRefOnRightSide = ref.current.offsetParent.clientWidth - linkPosition.x - (ref.current.offsetWidth + 30);

    if (showRefOnRightSide > 0) {
      setShowOnRightSide(true);
      setPositionWidth(DEFAULT_PADDING);
      setHyperlinkStyle({paddingLeft: isTooltipPositionFixed ? '24px' : ''});
      setCloseButtonStyle({left: '0px'});
    } else {
      setShowOnRightSide(false);
      setPositionWidth(-ref.current.offsetWidth - DEFAULT_PADDING);
      setHyperlinkStyle({paddingRight: isTooltipPositionFixed ? '24px' : ''});
      setCloseButtonStyle({right: '0px'});
    }

    if (ref.current.offsetParent.clientWidth / 2 < ref.current.offsetWidth) {
      setRefWidth(ref.current.offsetParent.clientWidth / 1.7);
    }
  }, [ref.current?.offsetParent.clientWidth, linkPosition.x, ref.current?.offsetWidth, isTooltipPositionFixed]);

  function handleOnClick() {
    manageFixedPositionOnTooltip(false)
  }

  return (
    <div ref={ref} className="pixel-board-tooltip position-absolute" style={componentStyle}>

      <div className="position-relative d-inline-block p-2 py-1 border border-info rounded bg-white">
        <div
          className={'pixel-board-tooltip__arrow ' + (showOnRightSide ? 'pixel-board-tooltip__arrow--right' : 'pixel-board-tooltip__arrow--left')}></div>

        {linkHref && <a href={linkHref} target="_blank" style={hyperlinkStyle}>{linkName}</a>}

        {!linkHref && <span style={hyperlinkStyle}>{linkName} <HashLink smooth
          to={'/' + ROUTE_NAMES.buyPixels + '#buy-pixels'}>$</HashLink></span>}

        {
          isTooltipPositionFixed &&
          <span className="btn-close p-2" onClick={handleOnClick}
            style={{
              position: 'absolute',
              top: '0px',
              display: 'inline-block',
              ...closeButtonStyle,
            }}>&nbsp;</span>
        }
      </div>
    </div>
  );
}

