import React from "react";
import Button from "../shared/components/Buttons/Button";

export default function PurchaseSuccess() {
  return (
    <div className="p-3 ">
      <h1 className="h2">Purchase Success</h1>
      <hr />
      <p className="fw-bold">Thank you for your purchase!<br /> These pixels bring luck, so maybe buy a few more? ;)</p>
      <div className="mt-4">
        <a className="me-2" href={'/buy-pixels'}><Button>Sure!</Button></a>
        <a href={'/'}><Button>Later</Button></a>
      </div>
    </div>
  );
}
