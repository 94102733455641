import React from "react";
import {Link} from "react-router-dom";

const pixelList = (await import("../../data/" + process.env.REACT_APP_ENVIRONMENT + "/pixel-list/pixel-list")).default;

let circleStyle = {
  height: '7px',
  width: '7px',
  borderRadius: '50%',
  display: 'inline-block',
  marginBottom: '2px',
}

export default function Header() {
  let totalPixelsBought = 0;

  for (let item of pixelList) {
    totalPixelsBought += item.units * 100;
  }

  return (
    <div className="row g-0 justify-content-between p-3">
      <div className="col-sm-auto"><Link to="/" reloadDocument><img className="w-100" src="/img/logo.png" alt="Logo" /></Link>
      </div>
      <div className="col-sm-auto text-center text-sm-end">
        <span>
          <span className="d-none d-lg-inline">
            <b>1,024,000,000</b> pixels&nbsp;
            <span className="bg-main-accent" style={circleStyle}></span> starting at <b>10¢</b> per pixel
          </span>
          &nbsp;
          <span className="bg-main-accent" style={circleStyle}></span> Own a piece of <b>internet history!</b>
          <br />
          Remaining <b>{(1_024_000_000 - totalPixelsBought).toLocaleString('en-US')}</b> pixels
        </span>
      </div>
    </div>
  );
}
