import React from "react";

const data = (await import("../data/" + process.env.REACT_APP_ENVIRONMENT + "/pixel-list/pixel-list")).default;

export default function PixelList() {
  data.reverse();

  return (
    <div className="p-3">
      <h1 className="h2">Pixel List</h1>
      <hr />

      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead>
          <tr>
            <th className="h3">Date of purchase</th>
            <th className="h3">Position</th>
            <th className="h3">Website</th>
            <th className="h3">Pixels</th>
          </tr>
          </thead>
          <tbody>
          {data.map((row, index) =>
            <tr key={index}>
              <td>{row.date}</td>
              <td>{"Row " + row.position.row + " Square " + row.position.square}</td>
              <td><a href={row.website.link} target="_blank">{row.website.title}</a></td>
              <td>{row.units * 100}</td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
