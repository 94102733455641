import React from 'react';
import {STEP_ONE} from "../../constants/constants";

export default function Step1({square, area}) {
  return (
    <div className="border shadow p-3 rounded overflow-auto">
      <h3 className={'text-center'}>Step {STEP_ONE} - Select Square</h3>
      <div className={'m-auto'} style={{width: 'max-content'}}>
        <div className="row g-0">
          <div className="col">
            {`Row: ${square?.position.y != undefined ? square?.position.y + 1 : 0} 
            Square: ${square?.position.x != undefined ? square?.position.x + 1 : 0}`}
          </div>
          <div className="col-auto">${square?.level}0.00 USD</div>
        </div>
        {area}
      </div>
    </div>
  );
}
