import React, {useState} from 'react';
import {MAX_SQUARE_UNITS, STEP_TWO} from "../../constants/constants";

export default function BuySquare(props) {
  let square = props.square;
  let [isHovered = false, setIsHovered] = useState();
  let backgroundColor = isHovered ? 'bg-main-accent-light' :
    (square.unitsRendered ? (square.unitsRendered == MAX_SQUARE_UNITS ? 'bg-secondary-accent' : 'bg-main-accent') : 'white');

  let handleMouseOver = () => {
    props.updateSelectedSquare(square);
    setIsHovered(true);
  };

  let handleMouseLeave = () => {
    setIsHovered(false);
  };

  let squareStyle = {
    float: 'left',
    width: '24px',
    height: '24px',
    lineHeight: '24px',
    textAlign: 'center',
    fontSize: '16px',
    border: '1px solid #d6d6d6',
    cursor: 'pointer'
  };

  return (
    <span
      className={backgroundColor}
      style={squareStyle}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        props.updateStep(STEP_TWO);
        props.updateSelectedSquare(square);
      }}
    >{square.level}
      </span>
  );
}
