import React from 'react';

export default class PixelBoardMap extends React.Component {
  square;
  squareId;
  handlePixelUnitClick;

  constructor(props) {
    super(props);

    this.square = props.square;
    this.squareId = props.squareId;
    this.handlePixelUnitClick = props.handlePixelUnitClick;
  }

  render() {
    return (
      <map id='pixel-board__map' name='pixel-board__map'>
        {this.props.square.map((item, index) => {
          return <area
            className="user-select-none"
            key={this.props.squareId + '.' + index}
            shape={'rect'}
            coords={item.coords.x1 + ',' + item.coords.y1 + ',' + item.coords.x2 + ',' + item.coords.y2}
            href={item.href}
            alt={item.alt}
            onClick={this.handlePixelUnitClick}
          />;
        })}

        <area className="user-select-none" shape={'default'} alt={'Available pixels'}
          onClick={this.handlePixelUnitClick} />
      </map>
    );
  }
}
