import React, {useState} from "react";
import squares from "../../../Squares/services/SquareGeneratorService";
import BuySquare from "../BuySquare";
import {STEP_ONE} from "../../constants/constants";
import Step1 from "../Step1";
import Step2 from "../Step2";

export default function BuySquares() {
  let [selectedSquare, setSelectedSquare] = useState();
  let [currentStep = STEP_ONE, setStep] = useState();
  let area = [];

  function updateSelectedSquare(square) {
    setSelectedSquare({...square});
  }

  function updateStep(stepNumber) {
    setStep(stepNumber);
  }

  for (let row of squares) {
    let brKey = '';
    for (let square of row) {

      area.push(
        <BuySquare square={square} key={square.position.y + '_' + square.position.x}
                   updateStep={updateStep} updateSelectedSquare={updateSelectedSquare} />
      );
      brKey = square.position.y + '_' + square.position.x + 'br';
    }

    area.push(<br style={{clear: 'both'}} key={brKey} />);
  }

  return (
    <div>
      <h2 id="buy-pixels" className="text-center">Buy Pixels</h2>
      {currentStep === STEP_ONE ?
        <Step1 square={selectedSquare} area={area} />
        :
        <Step2 square={selectedSquare} updateStep={updateStep} />
      }
    </div>
  );
}
