import React from 'react';
import {useParams} from "react-router-dom";
import SquareArea from "./SquareArea";
import {AREA_BREADTH} from "../../features/Squares/constants/constants";
import Error404 from "../errors/404";

export default function SquareAreaWrapper() {
  const {rowId, squareId} = useParams();

  if (!rowId && !squareId) {
    return <SquareArea />;
  }

  if (!validateParams(rowId, squareId)) {
    return <Error404 />;
  }

  function validateParams(rowId, squareId) {
    return parseInt(rowId) == rowId && rowId >= 1 && rowId <= AREA_BREADTH &&
      parseInt(squareId) == squareId && squareId >= 1 && squareId <= AREA_BREADTH;
  }

  return (
    <SquareArea position={{y: rowId - 1, x: squareId - 1}} />
  );
}
