import {BrowserRouter, Route, Routes} from "react-router-dom";
import Template from "../../pages/includes/Template";
import BuyPixels from "../../pages/BuyPixels";
import PixelList from "../../pages/PixelList";
import FAQ from "../../pages/FAQ";
import ContactMe from "../../pages/ContactMe";
import PurchaseSuccess from "../../pages/PurchaseSuccess";
import SquareAreaWrapper from "../../pages/SquareArea/SquareAreaWrapper";
import Error404 from "../../pages/errors/404";

export default function () {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<Template />}>
          <Route index element={<SquareAreaWrapper />} />
          <Route path="/squares/:rowId/:squareId" element={<SquareAreaWrapper />} />
          <Route path="/buy-pixels" element={<BuyPixels />} />
          <Route path="/buy-pixels/success" element={<PurchaseSuccess />} />
          <Route path="/pixel-list" element={<PixelList />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact-me" element={<ContactMe />} />

          <Route
            path="*"
            element={
              <Error404 />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
