import {calculateCellLevel} from './LevelCalculatorService';
import {AREA_BREADTH} from "../constants/constants";
import generateRow from "./RowGeneratorService";

let squares = [];

function generateSquares() {
  const result = [];

  for (let i = 0; i < AREA_BREADTH; i++) {
    let row = null;

    try {
      row = (require("../../../data/" + process.env.REACT_APP_ENVIRONMENT + "/squares/" + "r" + (i + 1) + ".js")).default;
    } catch (e) {
      row = null;
    }

    if (row) {
      result.push(row);
    } else {
      result.push(generateRow(i));
    }
  }

  return result;
}

function generateRawSquares(squares) {
  let defaultSquare;

  for (let y = 0; y < AREA_BREADTH; y++) {
    squares.push([]);

    for (let x = 0; x < AREA_BREADTH; x++) {
      defaultSquare = {
        imageName: null,
        unitsRendered: 0,
        position: {y: y, x: x},
        level: calculateCellLevel(AREA_BREADTH, y, x),
        //map format: [{coords: {x1: 0, y1: 0, x2: 10, y2: 10}, href: 'https://kuts-game.com?1', alt: 'Lorem ipsum'},...],
        map: []
      };

      squares[y].push(defaultSquare);
    }
  }

  return squares;
}


function generateDummySquares(squares) {
  let firstRow = {
    imageName: '1.png',
    unitsRendered: 10, //1 unit is 10x10 pixels
    position: {},
    map: [
      {coords: {x1: 0, y1: 0, x2: 10, y2: 10}, href: 'https://kuts-game.com?1', alt: 'Lorem ipsum'},
      {coords: {x1: 10, y1: 0, x2: 20, y2: 10}, href: 'https://kuts-game.com?2', alt: 'Blabla'},
      {coords: {x1: 20, y1: 0, x2: 30, y2: 10}, href: 'https://kuts-game.com?3', alt: '3'},
      {coords: {x1: 30, y1: 0, x2: 40, y2: 10}, href: 'https://kuts-game.com?4', alt: '4'},
      {coords: {x1: 40, y1: 0, x2: 50, y2: 10}, href: 'https://kuts-game.com?5', alt: '5'},
      {coords: {x1: 990, y1: 0, x2: 1000, y2: 10}, href: 'https://kuts-game.com?100', alt: '6'},
      {coords: {x1: 10, y1: 20, x2: 20, y2: 30}, href: 'https://kuts-game.com?6', alt: '7'},
      {coords: {x1: 20, y1: 20, x2: 30, y2: 30}, href: 'https://kuts-game.com?7', alt: '8'},
      {coords: {x1: 30, y1: 20, x2: 40, y2: 30}, href: 'https://kuts-game.com?8', alt: '9'},
      {coords: {x1: 40, y1: 20, x2: 50, y2: 30}, href: 'https://kuts-game.com?9', alt: '10'},
      {coords: {x1: 50, y1: 20, x2: 60, y2: 30}, href: 'https://kuts-game.com?10', alt: '11'},
      {coords: {x1: 970, y1: 980, x2: 990, y2: 1000}, href: 'https://kuts-game.com?cube', alt: '12'},
      {coords: {x1: 990, y1: 990, x2: 1000, y2: 1000}, href: 'https://kuts-game.com?1000', alt: '13'},
    ]
  };

  for (let y = 0; y < AREA_BREADTH; y++) {
    squares.push([]);

    for (let x = 0; x < AREA_BREADTH; x++) {
      let object = {};

      if (y === 0) {
        object = Object.assign({}, firstRow);
      } else {
        object = {
          imageName: (y <= 3 ? y + 1 + '.png' : 'sky-scraper.jpeg'),
          unitsRendered: Math.floor(Math.random() * 200) === 0 ? Math.floor(Math.random() * 11) : 0,
          position: {},
          map: [
            {coords: {x1: 0, y1: 0, x2: 10, y2: 10}, href: 'https://kuts-game.com?1', alt: 'Lorem ipsum'},
            {coords: {x1: 10, y1: 0, x2: 20, y2: 10}, href: 'https://kuts-game.com?2', alt: 'Blabla'},
          ]
        };
      }

      object.position = {y: y, x: x};
      object.level = calculateCellLevel(AREA_BREADTH, y, x);

      squares[y].push(object);
    }
  }

  return squares;
}

// squares = generateDummySquares(squares);
// squares = generateRawSquares(squares);
squares = generateSquares(squares);

export default squares;
