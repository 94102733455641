const ROUTE_NAMES = {
  homepage: '/',
  squares: 'squares',
  buyPixels: 'buy-pixels',
  pixelList: 'pixel-list',
  faq: 'faq',
  contactMe: 'contact-me'
};

export default ROUTE_NAMES;
