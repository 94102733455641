import React from 'react';
import {MAX_SQUARE_UNITS, STEP_ONE, STEP_TWO} from "../../constants/constants";
import PayPalForm from "../PayPalForm";

export default function Step2(props) {
  let square = props.square;
  let unitsStillAvailable = MAX_SQUARE_UNITS - square.unitsRendered;


  return (
    <div className="border shadow p-3 rounded">
      <h3 className={'text-center'}>Step {STEP_TWO} - Square Information</h3>
      <div className="mt-5">{`Buying units on row ${square.position.y + 1} square ${square.position.x + 1}.`}</div>
      <p>This square has <b>{square.unitsRendered}</b> units occupied and <b>{unitsStillAvailable}</b> units can
        still be purchased.
      </p>

      <div>
        {!!unitsStillAvailable &&
          <>
            <p>
              At level <b>{square.level}</b> one unit costs <b>${square.level}0.00 USD</b><br />
              You can change the amount of units you want to buy in the paypal popup window.
            </p>

            {<PayPalForm level={square.level} row={square.position.y + 1} square={square.position.x + 1} />}
          </>
        }

        {!unitsStillAvailable &&
          <p>There are no more units to be bought on this square. Please check another square.</p>
        }
      </div>

      <div className={'mt-5 mb-3'}>
        <button className={'btn btn-secondary'} onClick={() => props.updateStep(STEP_ONE)}>Back</button>
      </div>
    </div>
  );
}
