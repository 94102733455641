import generateRow from "../../../features/Squares/services/RowGeneratorService";

const row = generateRow(0);

const square1 = row[0];
square1.imageName = 'r1s1.png';
square1.unitsRendered = 4 + 36 + 1 + 1;
square1.map = [
  {coords: {x1: 0, y1: 0, x2: 20, y2: 20}, href: 'https://chroniker.co/', alt: 'Free Time Management, Agile Tools, Task Time Tracking'},
  {coords: {x1: 470, y1: 470, x2: 530, y2: 530}, href: 'https://vrfitbody.com/', alt: 'Weight loss in virtual reality'},
  {coords: {x1: 490, y1: 0, x2: 500, y2: 10}, href: 'https://fate-go.us/', alt: 'No Waifu = No Laifu'},
  {coords: {x1: 880, y1: 150, x2: 890, y2: 160}, href: 'https://www.udemy.com/course/edit-images-in-photopea-absolute-beginner-free-photoshop/?fbclid=IwAR2HYiOWgFiEdH8wWLCzEZR2GDkacjAkSHLwzf5AOt8_Vo27NzoPM9rjgxc', alt: 'Level Up your brain'},
];

export default row;
