import React, {useEffect, useRef} from "react";
import {Modal} from "bootstrap/dist/js/bootstrap.bundle.min";

export default function MainModal({message}) {
  const modalRef = useRef(null);

  useEffect(() => {
      if (!message) {
        return;
      }

      new Modal(modalRef.current).show();
    }
  );

  return (
    <div ref={modalRef} className="modal fade" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLabel">Notice</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {message}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-info text-white" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  );
}
