import React, {useState} from 'react';
import PixelBoard from "../../features/PixelBoard/components/PixelBoard";
import squares from "../../features/Squares/services/SquareGeneratorService";
import SquaresFinder from "../../features/Squares/components/SquareFinder";

export default function SquareArea({position}) {
  let [squarePosition, setSquarePosition] = useState(position);

  return (
    <React.Fragment>
      <SquaresFinder position={squarePosition} squares={squares} onSquareChange={setSquarePosition} />

      <div className="row g-0">
        {squarePosition &&
          <PixelBoard squares={squares} squarePosition={squarePosition} key={JSON.stringify(squarePosition)} />}
      </div>
    </React.Fragment>
  );
}
