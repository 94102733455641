import React, {useState} from "react";
import {BsArrowRight, BsSearch} from "react-icons/bs";
import Button from "../../../../../shared/components/Buttons/Button";
import {Link} from "react-router-dom";

export default function SquarePositionRenderer({position}) {
  const STATE_SEARCH = 'search';
  const STATE_RENDER = 'render';

  let row = position.y + 1;
  let square = position.x + 1;
  let [rowValue, setRowValue] = useState(position.y + 1);
  let [squareValue, setSquareValue] = useState(position.x + 1);
  let [state, setState] = useState(STATE_RENDER);

  function handleChangeRow(event) {
    if (!validateInput(event.target.value)) {
      return;
    }

    setRowValue(event.target.value);
  }

  function handleChangeSquare(event) {
    if (!validateInput(event.target.value)) {
      return;
    }

    setSquareValue(event.target.value);
  }

  function validateInput(value) {
    return value === '' || Number.isInteger(value * 1);
  }

  return (
    <>
      <span className="h2">
        {state === STATE_SEARCH ?
          <>
            Row < input type="text" value={rowValue} onChange={handleChangeRow}
                        style={{width: '3rem', height: '2.5rem'}} /> &nbsp;
            Square <input type="text" value={squareValue} onChange={handleChangeSquare}
                          style={{width: '3rem', height: '2.5rem'}} />
          </>
          :
          'Row ' + row + ' Square ' + square
        }

        {state === STATE_SEARCH ?
          <Link to={"/squares/" + rowValue + '/' + squareValue} reloadDocument>
            <Button className={'rounded-circle p-2 ms-2'}>
              <BsArrowRight className="d-block" />
            </Button>
          </Link>
          :
          <Button className={'rounded-circle p-2 ms-2'} onClick={() => {
            setState(STATE_SEARCH);
          }}>
            <BsSearch className="d-block" />
          </Button>
        }

      </span>
    </>
  );
}
