import {React, useState} from "react";

export default function PayPalForm(props) {
  const PAYPAL_BUY_NOW_LEVEL_1_10_ID = process.env.REACT_APP_PAYPAL_BUY_NOW_LEVEL_1_10_ID;
  const PAYPAL_BUY_NOW_LEVEL_11_16_ID = process.env.REACT_APP_PAYPAL_BUY_NOW_LEVEL_11_16_ID;
  const PAYPAL_WEBSITE = process.env.REACT_APP_PAYPAL_WEBSITE;
  const IS_STANDARD = props.level < 11;
  const imageStyle = {height: '47px', opacity: .9};
  let paypalButtonId = IS_STANDARD ? PAYPAL_BUY_NOW_LEVEL_1_10_ID : PAYPAL_BUY_NOW_LEVEL_11_16_ID;
  let [imgHovered, setImgHovered] = useState(false);

  function handleImgMouseOver() {
    setImgHovered(true);
  }

  function handleImgMouseLeave() {
    setImgHovered(false);
  }

  return (
    <form className={'mt-4 text-center'} action={PAYPAL_WEBSITE + '/cgi-bin/webscr'} method="post" target="_top">
      <input type="hidden" name="cmd" value="_s-xclick" />
      <input type="hidden" name="hosted_button_id" value={paypalButtonId} />
      <table>
        <tbody>
        <tr>
          <td><input type="hidden" name="on0" value="Levels" />
            <input type="hidden" name="custom"
              value={'level: ' + props.level + ', row: ' + props.row + ', square: ' + props.square} />
          </td>
        </tr>
        <tr>
          <td>
            <select className={'selected-level d-none'} name="os0" value={'Level ' + props.level} readOnly>
              {IS_STANDARD && <>
                <option value="Level 1">Level 1 $10.00 USD</option>
                <option value="Level 2">Level 2 $20.00 USD</option>
                <option value="Level 3">Level 3 $30.00 USD</option>
                <option value="Level 4">Level 4 $40.00 USD</option>
                <option value="Level 5">Level 5 $50.00 USD</option>
                <option value="Level 6">Level 6 $60.00 USD</option>
                <option value="Level 7">Level 7 $70.00 USD</option>
                <option value="Level 8">Level 8 $80.00 USD</option>
                <option value="Level 9">Level 9 $90.00 USD</option>
                <option value="Level 10">Level 10 $100.00 USD</option>
              </>
              }
              {!IS_STANDARD && <>
                <option value="Level 11">Level 11 $110.00 USD</option>
                <option value="Level 12">Level 12 $120.00 USD</option>
                <option value="Level 13">Level 13 $130.00 USD</option>
                <option value="Level 14">Level 14 $140.00 USD</option>
                <option value="Level 15">Level 15 $150.00 USD</option>
                <option value="Level 16">Level 16 $160.00 USD</option>
              </>
              }
            </select>
          </td>
        </tr>
        </tbody>
      </table>
      <input type="hidden" name="currency_code" value="USD" />
      <input type="image" src={PAYPAL_WEBSITE + '/en_US/i/btn/btn_buynowCC_LG.gif'} border="0"
        name="submit" alt="PayPal - The safer, easier way to pay online!"
        onMouseOver={handleImgMouseOver} onMouseLeave={handleImgMouseLeave}
        style={imgHovered ? {...imageStyle, opacity: 1} : imageStyle} />
      <img alt="" border="0" src={PAYPAL_WEBSITE + '/en_US/i/scr/pixel.gif'} width="1" height="1" />
    </form>
  );
}
